<template lang="pug">
.confirm-email
  h1 Confirmação de email

  .body

    .loading(v-if="loading")
      p Aguardando confirmação do email...

      Loading

    .success(v-else-if="confirmed")
      p Seu email foi confirmado com sucesso! Agora você pode acessar sua conta Magenta.

      Button(
        label="Acessar minha conta",
        :to="{ name: 'sign-in' }",
        rounded
      )

    .expired-ticket(v-else-if="expiredTicket")
      p Este link de confirmação expirou.

      p Por favor, recadastre a sua senha para confirmar o seu e-mail

      Button(
        label="Acessar minha conta",
        :to="{ name: 'reset-password' }",
        rounded
      )

    .error(v-else-if="failed")
      p Ocorreu um erro ao confirmar o email.

      p Por favor, tenta novamente mais tarde.

      p
        | Se o problema persistir, por favor, entre em contato com o
        | suporte.

      Button(
        label="Voltar",
        :to="{ name: 'home' }",
        rounded
      )

</template>
<script setup lang="ts">
import type { FetchError } from 'ofetch'
import type { Ticket } from '~/services/auth'

import { default as AuthService, decodeTicket } from '~/services/auth'

const route = useRoute()

const loading = ref(true)
const confirmed = ref(false)
const expiredTicket = ref(false)
const failed = ref(false)

const confirmEmail = async (ticket: Ticket) => {
  loading.value = true

  try {
    await AuthService.confirmEmail({ email: ticket.email, ticket: ticket.ticket })

    confirmed.value = true
  } catch (error: FetchError | any) {
    if ("status" in error && error.status === 403) {
      expiredTicket.value = true
    } else {
      captureException(new Error('Failed to confirm email', { cause: error}))

      failed.value = true
    }
  } finally {
    loading.value = false
  }
}

onMounted(() => {
  const ticket = decodeTicket(route.params["ticket"] as string)

  confirmEmail(ticket)
})
</script>
<style lang="sass" scoped>
.confirm-email
  position: relative
  max-width: 708px
  margin: 0 auto
  padding: 0 14px
  text-align: center
  animation: fade-in .2s forwards

  .body
    margin: 32px 0

    p
      margin: 16px 0

    .button
      margin: 32px 0
      font-size: 24px
      height: 2em
      padding: 0 32px
</style>